@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

h2 {
  font-size: 38px;
  font-family: RobotBold, sans-serif;
  font-weight: 900;
  color: var(--secondary-color);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  font-family: RobotBold, sans-serif;
  color: var(--secondary-color);
}

.table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.savebtn {
  width: 8em;
  background-color: var(--main-color) !important;
  border: none;
}

.savebtnLarge {
  width: 12em;
  background-color: var(--main-color) !important;
  border: none;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-bootstrap-table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    border-top: none !important;
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.react-bootstrap-table .carret {
  color: black !important;
}

.newRow {
  padding: 1em;
  height: 100%;

  @include media-breakpoint-down(xs) {
    height: 100%;
    padding: 0;
  }
}

.textContainer {
  width: 50%;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 1.6em;
  box-shadow: 1px 1px #00000014;
  border: 1px solid #efefef;
  opacity: 1;
  font-family: RobotoRegular, sans-serif;
  font-size: 1.5em;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    font-size: 1em;
    height: 2em;
  }
}

.paginate-container {
  display: flex;
  padding: 0 1em;
  justify-content: space-between;
  width: 100%;

  li {
    display: inline-block;
    padding: 0;
  }
}

.paginate-active {
  font-weight: bolder;
}

.paginate-disabled {
  a {
    cursor: none;
  }
}

.reservationButton {
  width: 100%;
  margin-top: 1.3em;
  background-color: var(--main-color) !important;
  height: 2.3em;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: white !important;
    font-size: 22px !important;
    font-family: RobotoBold, sans-serif !important;
  }

  svg {
    width: 1em;
    height: 1.2em;
    margin-left: 1em;
    margin-bottom: 0.2em;
    color: #fff;
  }

  @include media-breakpoint-down(md) {
    align-items: center;
    width: 100%;
    margin: auto;
    height: 2.3em;

    span {
      font-size: 10px;
    }

    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }
}

.timeslotSelection {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 0.3em;
  padding-top: 0;

  span {
    font-size: 20px;
    font-family: RobotoBold, sans-serif;
  }

  button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 26px;
    font-family: RobotoBold, sans-serif;

    p {
      margin-bottom: 0;
    }

    svg {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    align-items: center;
    width: 100%;
    padding: 1em;
    padding-top: 0;
  }
}

.timeslot {
  font-size: 18px !important;
  font-family: RobotoBold, sans-serif;
  color: black !important;
}

.timescontainer {
  margin-top: 0.5em;
  width: 100%; // important because else if last page is not full the width changes somehow
}

.left {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    height: 100%;
    width: 85%;
    border-radius: 0.4em;
    z-index: 1;
    max-height: 1000px;
    max-width: 800px;
  }

  @include media-breakpoint-down(lg) {
    .form {
      width: 50%;
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.right {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5em;
  padding-right: 5em !important;

  .headerText {
    margin-bottom: 0.5em;

    h2 {
      color: black;
      font-family: RobotoBold, sans-serif;
      font-weight: 900 !important;
      font-size: 64px;
    }

    p {
      font-family: RobotoRegular, sans-serif;
      color: black;
      margin-top: 1em;
    }

    @include media-breakpoint-down(xs) {
      flex-direction: column;
      width: 100%;
      margin-bottom: 0;

      h2 {
        font-size: 26px !important;
        text-align: center;
        position: absolute;
        top: 0;
        color: black;
        margin-top: 0.2em;
        margin-left: 0.2em;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }

      .mobileImage {
        display: block;
        height: 7em;
        width: 100%;
        object-fit: cover;
      }
    }

    @include media-breakpoint-up(md) {
      .mobileImage {
        display: none;
      }
    }
  }

  .inputForm {
    border-radius: 0.3em;
    width: 100%;
    padding: 3em;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    label {
      font-size: 24px;
      font-family: RobotoBold, sans-serif;
      color: black;
    }

    span {
      font-size: 24px;
      font-family: RobotoBold, sans-serif;
      color: black;
    }

    h4 {
      margin-bottom: 0;
      font-size: 24px;
      font-family: RobotoBold, sans-serif;
      color: black;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    @include media-breakpoint-down(xs) {
      background-color: #fff;
      flex-direction: column;
      margin-top: 0 !important;
      padding: 1em;
      width: 100%;
      position: relative;
      top: -1em;
    }
  }

  .messageForm {
    @include media-breakpoint-down(xs) {
      padding: 1em;
    }
  }

  .textCenter {
    font-size: 24px;
    font-family: RobotoRegular, sans-serif;
    color: black;

    @include media-breakpoint-down(xs) {
      font-size: 16px;
    }
  }

  .formInput {
    width: 50%;
    border: 0.1em rgb(231, 231, 231) solid;
    border-radius: 0.3em;
    padding: 0.5em;
    height: 100%;
    margin-bottom: 0;

    label {
      color: var(--main-color) !important;
      font-family: RobotoBold, sans-serif;
      font-size: 32px;

      @include media-breakpoint-down(xs) {
        font-size: 18px;
      }
    }

    input {
      font-size: 26px;
      font-family: RobotoBold, sans-serif;
      color: #979292;
      border: none;
      cursor: pointer;

      @include media-breakpoint-down(xs) {
        padding-left: 0.5em;
        font-size: 18px !important;
      }
    }

    .select {
      font-size: 26px;
      font-family: RobotoBold, sans-serif;
      color: #979292;
      border: none;
      cursor: pointer;

      @include media-breakpoint-down(xs) {
        font-size: 20px !important;
      }
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      border: none;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 0 !important;

    .form {
      padding: 1em;
      margin-top: 1em;
      width: 100%;
    }

    h2 {
      // display: none;
      font-size: 32px !important;
    }

    p {
      display: none;
    }

    img {
      display: none;
    }
  }
}
