@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

h2 {
  font-size: 38px;
  font-family: RobotBold, sans-serif;
  font-weight: 900;
  color: var(--secondary-color);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  font-family: RobotBold, sans-serif;
  color: var(--secondary-color);
}

.table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.savebtn {
  width: 8em;
  background-color: var(--main-color) !important;
  border: none;
}

.savebtnLarge {
  width: 12em;
  background-color: var(--main-color) !important;
  border: none;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-bootstrap-table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    border-top: none !important;
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.react-bootstrap-table .carret {
  color: black !important;
}

.priceCard {
  padding: 2em;
  border-radius: 0.5em !important;
  background-color: white;
  height: 100%;
  border: 1px solid #e2e2e2;
}

.blackTitle {
  color: black !important;
  margin-bottom: 0.5em;
}

.cardCol {
  margin-bottom: 1em;
}

.upperCardRow {
  display: flex;
  flex-direction: column;
  border-bottom: 2px dashed lightgray;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    font-size: 2em;
    font-family: RobotoBold, sans-serif;
    color: #313131;

    @include media-breakpoint-down(sm) {
      font-size: 1.2em;
    }
  }

  span {
    font-size: 2em;
    font-family: AdobeCleanLight, sans-serif;
    color: #313131;

    @include media-breakpoint-down(sm) {
      font-size: 1.2em;
    }
  }

  .price {
    font-size: 1.5em;
    font-family: RobotoBold, sans-serif;
    color: #313131;
    margin-top: 0.2em;

    @include media-breakpoint-down(sm) {
      font-size: 1.2em;
      margin-top: 0;
    }
  }
}

.lowerCardRow {
  margin-top: 1em;
  display: flex;
  flex-direction: row;

  svg {
    color: var(--main-color);
    width: 1.5em;
    height: 1.5em;
  }

  p {
    margin-left: 1em;
    color: #707070;
    font-size: 18px;
    font-family: RobotoRegular, sans-serif;
  }
}

.detailText {
  color: #707070;
  font-size: 18px;
  font-family: RobotoRegular, sans-serif;
}

.button {
  background-color: var(--main-color) !important;
  width: 100%;
  height: 3em;
  margin-top: 1.5em;

  svg {
    width: 1em;
    height: 1.2em;
    margin-left: 0.8em;
    margin-bottom: 0.3em;
  }

  span {
    color: white;
    font-size: 20px;
    font-family: RobotoBold, sans-serif;
  }
}

.infoCard {
  height: 100%;

  & > img:first-child {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.8;
  }

  & > img:nth-child(2) {
    height: 6em;
    width: 6em;
    bottom: -3em;
    left: 10em;
    position: absolute;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.rolePriceCard {
  padding: 2em;
  height: 100%;
  background: transparent linear-gradient(153deg, #213681 0%, #0a1748 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  opacity: 1;

  svg {
    color: white;
    width: 3em;
    height: 3em;
    margin-bottom: 0.8em;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    color: white;
    font-size: 18px;
    font-family: RobotoRegular, sans-serif;
    margin-bottom: 1em;

    svg {
      color: white;
      width: 1.5em;
      height: 1.5em;
    }
  }

  p {
    color: white;
    font-size: 32px;
    font-family: RobotoBold, sans-serif;

    @include media-breakpoint-down(xs) {
      font-size: 26px;
    }
  }

  button {
    background-color: white;
    width: 70%;
    height: 3em;

    svg {
      width: 1em;
      height: 1.2em;
      margin-left: 1em;
      margin-bottom: 0.2em;
      color: var(--main-color);
    }

    span {
      color: var(--main-color);
      font-size: 20px;
      font-family: RobotoBold, sans-serif;
    }

    @include media-breakpoint-down(xs) {
      svg {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    button {
      width: 100%;
    }
  }
}
