@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

h2 {
  font-size: 38px;
  font-family: RobotBold, sans-serif;
  font-weight: 900;
  color: var(--secondary-color);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  font-family: RobotBold, sans-serif;
  color: var(--secondary-color);
}

.table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.savebtn {
  width: 8em;
  background-color: var(--main-color) !important;
  border: none;
}

.savebtnLarge {
  width: 12em;
  background-color: var(--main-color) !important;
  border: none;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-bootstrap-table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    border-top: none !important;
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.react-bootstrap-table .carret {
  color: black !important;
}

.blackTitle {
  color: black !important;
  margin-bottom: 0.5em;
}

.ticketCardColumn {
  margin-bottom: 1em;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.card {
  border-radius: 5px;
  height: 475px;
}

.img {
  width: 100%;
  height: 35%;
  object-fit: cover;
}

.cardImageContainer {
  background: black;
  height: 55%;
}

.cardFooter {
  width: 100%;
  border-top: 2px dashed lightgray;
  padding-top: 1em;

  span {
    font-size: 18px;
    font-family: RobotoRegular, sans-serif;
    color: #707070;
  }
}

.cardRow {
  display: flex;
  flex-direction: row;
}

.upperLeftCardRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;

  span {
    font-size: 30px;
    font-family: RobotoBold, sans-serif;
  }

  @include media-breakpoint-down(sm) {
    span {
      font-size: 26px;
    }
  }
}

.upperRightCardRow {
  width: 20%;
  text-align: right;
  margin-right: 1em;

  span {
    font-size: 60px;
    font-family: RobotoBold, sans-serif;
  }

  @include media-breakpoint-down(sm) {
    span {
      font-size: 45px;
    }
  }
}

.infoCard {
  height: 475px;
  padding: 1em;
  background: transparent linear-gradient(154deg, var(--main-color) 0%, #402b53 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  opacity: 1;

  svg {
    width: 100px;
    height: 100px;
    color: white;

    // @include media-breakpoint-down(sm) {
    //   width: 40px;
    //   height: 40px;
    // }
  }

  h2 {
    font-size: 36px;
    font-family: RobotoBold, sans-serif;
    color: white;
    margin-top: 1em;

    @include media-breakpoint-down(sm) {
      font-size: 26px !important;
    }
  }

  img {
    width: 6em;
    margin-top: 1em;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 18px;
    font-family: RobotoRegular, sans-serif;
    color: white;
    margin-top: 20px;
  }

  button {
    background-color: white;
    width: 50%;
    height: 56px;
    margin-top: 1.5em;

    span {
      color: var(--main-color);
      font-size: 20px;
      font-family: RobotoBold, sans-serif;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 50px;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 1em;
    height: 100%;
  }
}
