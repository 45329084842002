@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

h2 {
  font-size: 38px;
  font-family: RobotBold, sans-serif;
  font-weight: 900;
  color: var(--secondary-color);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  font-family: RobotBold, sans-serif;
  color: var(--secondary-color);
}

.table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.savebtn {
  width: 8em;
  background-color: var(--main-color) !important;
  border: none;
}

.savebtnLarge {
  width: 12em;
  background-color: var(--main-color) !important;
  border: none;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-bootstrap-table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    border-top: none !important;
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.react-bootstrap-table .carret {
  color: black !important;
}

.dateInput {
  width: 95% !important;
  padding: 0.5em !important;

  span {
    font-size: 26px !important;
    font-family: RobotoBold, sans-serif !important;
    color: #979292 !important;

    @include media-breakpoint-down(xs) {
      font-size: 20px !important;
    }
  }
}

.dialog-sheet {
  margin-left: 1em;
  background-color: rgb(251, 251, 251) !important;
  z-index: 1;
  border-radius: 0.3em;
}

.rdp-day {
  span {
    color: rgb(110, 110, 110) !important;
  }
}

.rdp-day_today {
  span {
    color: black !important;
  }
}

.rdp-day_selected {
  background-color: var(--main-color) !important;

  span {
    color: white !important;
  }
}

.rdp-cell {
  padding: 0.2em;

  span {
    font-size: 20px !important;
    font-family: RobotoBold, sans-serif !important;
  }

  @include media-breakpoint-down(xs) {
    padding: 0;
  }
}

.rdp-caption_label {
  font-size: 26px !important;
  font-family: RobotoBold, sans-serif !important;
}

.rdp-head_row {
  span {
    font-family: RobotoBold, sans-serif !important;
    font-size: 20px !important;
  }
}
