@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

h2 {
  font-size: 38px;
  font-family: RobotBold, sans-serif;
  font-weight: 900;
  color: var(--secondary-color);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  font-family: RobotBold, sans-serif;
  color: var(--secondary-color);
}

.table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.savebtn {
  width: 8em;
  background-color: var(--main-color) !important;
  border: none;
}

.savebtnLarge {
  width: 12em;
  background-color: var(--main-color) !important;
  border: none;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-bootstrap-table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    border-top: none !important;
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.react-bootstrap-table .carret {
  color: black !important;
}

.loginRow {
  height: 100% !important;
  background-color: white;
}

.card {
  border: none !important;
  width: 80%;
  margin: auto;

  h2 {
    font-family: RobotoBold, sans-serif;
    font-size: 64px;

    @include media-breakpoint-down(xs) {
      font-size: 42px;
    }
  }

  h4 {
    font-size: 22px;
    color: #979797;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.rightCol {
  background-color: #f3f4f8;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 500px;
    height: 500px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.img {
  max-height: 40%;
  object-fit: scale-down;
  padding: 0.5em;
}

.leftCol {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardBody {
  padding: 2em;
  overflow: auto;
  width: 100%;
}

.form {
  margin-top: 2em;
}

.formLabel {
  font-family: RobotoBold, sans-serif;
  font-size: 16px;
  color: #979797;
}

.formText {
  font-family: OpenSansRegular, sans-serif;
}

.loginButton {
  font-family: RobotoBold, sans-serif;
  float: right;
  background-color: var(--secondary-color) !important;
}

.button {
  font-family: RobotoBold, sans-serif;
  float: right;
  margin-top: 1em;
}

.buttonGroup {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.forgotpassword {
  span {
    font-family: OpenSansRegular, sans-serif;
    font-size: 14px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  text-align: center;
  padding-top: 1em;
}
