@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

h2 {
  font-size: 38px;
  font-family: RobotBold, sans-serif;
  font-weight: 900;
  color: var(--secondary-color);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  font-family: RobotBold, sans-serif;
  color: var(--secondary-color);
}

.table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.savebtn {
  width: 8em;
  background-color: var(--main-color) !important;
  border: none;
}

.savebtnLarge {
  width: 12em;
  background-color: var(--main-color) !important;
  border: none;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-bootstrap-table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    border-top: none !important;
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.react-bootstrap-table .carret {
  color: black !important;
}

.navbar {
  background-color: #f5f5f5;
  padding: 1em;

  @include media-breakpoint-up(sm) {
    height: 5em;
  }
}

.navbar-toggler collapsed {
    margin-right: 1em !important;
}

.navbar-toggler.collapsed {
    margin-right: 1em !important;
}

.navRight {
  // text-transform: uppercase;
  font-size: 1em;
  font-family: RobotoMedium, sans-serif;

  a {
    color: #333;
    margin-left: 1em;
    hover: {
      color: white;
    }
  }

  @include media-breakpoint-up(md) {
    height: 7em;
    align-items: center;
  }
}

.navBrand {
  margin-left: 0.5em;

  img {
    display: none;
  }

  span {
    font-family: RobotoMedium, sans-serif;
    font-size: 1em;
    color: #313131;
  }

  @include media-breakpoint-up(md) {
    img {
      display: inline-block;
      width: 2em;
      height: 2em;
    }

    span {
      margin-left: 0;
    }
  }
}

.navBrand:hover {
  cursor: pointer;
}
