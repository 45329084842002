@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

h2 {
  font-size: 38px;
  font-family: RobotBold, sans-serif;
  font-weight: 900;
  color: var(--secondary-color);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  font-family: RobotBold, sans-serif;
  color: var(--secondary-color);
}

.table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.savebtn {
  width: 8em;
  background-color: var(--main-color) !important;
  border: none;
}

.savebtnLarge {
  width: 12em;
  background-color: var(--main-color) !important;
  border: none;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-bootstrap-table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    border-top: none !important;
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.react-bootstrap-table .carret {
  color: black !important;
}

.container {
  height: 100%;
  background: white;
  justify-content: center;
  align-items: center;

  h2 {
    font-family: RobotoBold, sans-serif;
    font-size: 46px;
    text-align: center;
    margin-bottom: 0.2em;
    margin-top: 0.5em;

    @include media-breakpoint-down(xs) {
      font-size: 36px;
    }
  }

  span {
    font-family: RobotoBold, sans-serif;
    color: #979797;
    font-size: 22px;
    text-align: center;

    @include media-breakpoint-down(xs) {
      font-size: 16px;
    }
  }
}

.inner {
  margin-top: 2em;
  // padding: 1em !important;
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    font-size: 3em;
    color: #979797;

    @include media-breakpoint-down(xs) {
      font-size: 2em;
    }
  }

  form {
    margin-top: 1em;
    width: 80%;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  .formLabel {
    font-family: RobotoBold, sans-serif;
    font-size: 16px;
    color: #979797;
  }

  button {
    font-family: RobotoBold, sans-serif;
    background-color: var(--secondary-color) !important;
    width: 100%;
  }

  .goBack {
    margin-top: 1em;
    cursor: pointer;
    text-align: center;

    svg {
      font-size: 1em;
      color: #979797;
    }
  }
}

.success {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  background-color: white;
  margin-top: 2em;
  padding: 2em !important;
  align-items: center;

  button {
    margin-top: 1em;
    font-family: RobotoBold, sans-serif;
    background-color: var(--secondary-color) !important;
    width: 40%;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
}
