@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

h2 {
  font-size: 38px;
  font-family: RobotBold, sans-serif;
  font-weight: 900;
  color: var(--secondary-color);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  font-family: RobotBold, sans-serif;
  color: var(--secondary-color);
}

.table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.savebtn {
  width: 8em;
  background-color: var(--main-color) !important;
  border: none;
}

.savebtnLarge {
  width: 12em;
  background-color: var(--main-color) !important;
  border: none;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-bootstrap-table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    border-top: none !important;
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.react-bootstrap-table .carret {
  color: black !important;
}

.details {
  height: 100%;

  > img {
    width: 100%;
    height: 25%;
    padding: 1em;
    object-fit: contain;
  }
}

.detail {
  width: 40%;
  align-self: center;
  margin: auto !important;
  padding-top: 1em !important;

  h2 {
    font-size: 36px;
    font-family: RobotoBold, sans-serif;
    color: black !important;

    @include media-breakpoint-down(sm) {
      font-size: 26px !important;
    }
  }

  @include media-breakpoint-down(md) {
    width: 80%;
  }
}

.costDetail {
  p {
    color: black;
    font-size: 20px;
    font-family: RobotoBold, sans-serif;
  }

  span {
    color: gray;
    text-decoration: underline;
    font-size: 12px;
    font-family: RobotoBold, sans-serif;
    cursor: pointer;
  }
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  height: 5em;
  width: 100%;

  @include media-breakpoint-down(sm) {
    height: 4em;
    display: flex;
    flex-direction: column;
  }
}

.confirmButton {
  height: 3.5em;
  width: 40%;
  margin-top: 1em;
  background-color: var(--main-color) !important;

  span {
    color: white;
    font-size: 24px;
    font-family: RobotoBold, sans-serif;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  svg {
    width: 1em;
    height: 1.2em;
    margin-left: 1em;
    color: #fff;

    @include media-breakpoint-down(sm) {
      margin-left: 0.5em;
      width: 0.5em;
      height: 0.6em;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.cancelButton {
  height: 3.5em;
  width: 40%;
  margin-top: 1em;
  background-color: #555556 !important;

  span {
    color: white;
    font-size: 24px;
    font-family: RobotoBold, sans-serif;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0;
    width: 100%;
  }
}
