.rbc-time-slot {
  background-color: white !important;
}

.rbc-header {
  background-color: white !important;
  height: 1.2em;
}

.rbc-day-bg {
  background-color: white !important;
}