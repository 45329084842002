@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

h2 {
  font-size: 38px;
  font-family: RobotBold, sans-serif;
  font-weight: 900;
  color: var(--secondary-color);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  font-family: RobotBold, sans-serif;
  color: var(--secondary-color);
}

.table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.savebtn {
  width: 8em;
  background-color: var(--main-color) !important;
  border: none;
}

.savebtnLarge {
  width: 12em;
  background-color: var(--main-color) !important;
  border: none;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-bootstrap-table {
  border-radius: 0.5em !important;
  background-color: #efefef;

  th {
    border-top: none !important;
    font-family: RobotoRegular, sans-serif;
    color: #333;
    font-size: 26px !important;
  }

  td {
    font-family: RobotoRegular, sans-serif;
    color: #333;
  }
}

.react-bootstrap-table .carret {
  color: black !important;
}

.sidebar {
  width: 13em;
  background-color: #f5f5f5;
  height: 100%;
  padding: 0.5em;
  padding-left: 1em;
  border-right: 1px solid #efefef;
}

.navlink {
  font-size: 1em;
  font-family: RobotBold, sans-serif;
  color: #333;
  display: flex !important;
  align-items: center !important;
  padding-left: 0 !important;
  padding-top: 0.5em;

  svg {
    margin-right: 0.4em;
    font-size: 1.3em !important;
    color: var(--main-color) !important;
  }

  .nav-link:active {
    color: red !important;
    font-size: 10em;
  }

  .active {
    color: red !important;
    font-size: 10em;
  }
}

.activeLink {
  color: rgb(61, 58, 240) !important;

  svg {
    color: rgb(61, 58, 240) !important;
  }
}
